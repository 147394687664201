
import {
    computed, defineComponent, PropType
} from 'vue';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import initForm from '@/components/view/pm/set-old-resident-dialog/init-form';
import { phoneCode as phoneCodeOption, community } from '@/data';
import { isCNServer } from '@/util/location';
import { SubFormData } from '@/components/view/pm/set-old-resident-dialog/all.type';
import { residentDeviceApi, userForSingleApi } from '@/api';
import noticeBase from '@/util/notice.base';
import { getPhoneCodeOps } from '@/util/phone-code';

export default defineComponent({
    emits: ['submit', 'close'],
    components: {
        dialogShell
    },
    props: {
        opera: {
            type: String as PropType<'add' | 'edit'>,
            default: 'add'
        },
        subFormData: {
            type: Object as PropType<SubFormData>,
            default: () => ({})
        },
        mainId: {
            type: String,
            default: ''
        },
        mainEmail: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const {
            submit,
            formData,
            rules,
            formEle
        } = initForm(props.opera, props.subFormData, props.mainId, emit);

        const title = props.opera === 'add' ? WordList.TabelPersonUserTitle2Add : WordList.TabelPersonUserTitle2Modify;

        const isEnableLandline = computed(() => community.IsEnableLandline.value);

        const resetPw = () => {
            residentDeviceApi.resetPasswd({
                ID: props.subFormData.ID
            }, [(data: { msg: string }) => {
                const alertMsg = props.subFormData.Email === '' ? WordList.ResetSubUserWithoutEmail : data.msg;
                noticeBase.messageBox('alert', alertMsg, WordList.TabelChangePwStepSuccess, 'success')(() => false);
                emit('close');
            }, false]);
        };

        return {
            isCNServer: isCNServer(),
            submit,
            formData,
            isEnableLandline,
            rules,
            phoneCodeOption,
            formEle,
            title,
            resetPw,
            getPhoneCodeOps
        };
    }
});
